import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Check } from 'iconoir-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCatalogTranslation } from '@lib/hooks/use-catalog-translations';
import { useRegistrationForm } from '@lib/hooks/use-registration-form';
import CatalogButton from '@modules/common/components/catalog-button';
import CatalogSelect from '@modules/common/components/catalog-select';
import Input from '@modules/common/components/input';
import { Trans, useTranslation } from 'react-i18next';
import { useSettings } from '@lib/hooks/use-settings';
import { useLogin } from '@lib/context/login-context';
import { useRouter } from 'next/router';

const RegistrationForm = () => {
  const { registrationFormSchema: RegistrationFormSchema, countryOptions } =
    useRegistrationForm();
  const { onSubmit, hasRegistered, inputEmail, loginPageContent } = useLogin();
  const { subTitle, formCta } = loginPageContent;
  const router = useRouter();
  const [view, setView] = useState<JSX.Element>(null);
  const { ct } = useCatalogTranslation('common');
  const { t } = useTranslation('common');
  const { sellerName, termsAndConditions } = useSettings();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(RegistrationFormSchema),
  });

  useEffect(() => {
    if (!inputEmail) {
      router.push('/login?step=email');
    }
  }, []);
  const formView = countryOptions && (
    <div className="flex flex-col gap-8">
      <p>{subTitle}</p>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-5 w-full sm:w-3/4 max-w-[400px]"
      >
        <div>
          <Input
            {...register('companyName')}
            label={ct('Registration.companyName', 'Nom de la société')}
            placeholder={ct(
              'Registration.companyNamePlaceholder',
              'Entrez le nom de votre société',
            )}
            required={true}
            type="text"
          />
          <p className="p-0 text-xsmall text-error-50">
            {errors.companyName?.message}
          </p>
        </div>
        <div>
          <Input
            {...register('contactFirstName')}
            label={ct('Registration.contactFirstName', 'Prénom du contact')}
            placeholder={ct(
              'Registration.contactFistNamePlaceholder',
              'Entrez votre prénom',
            )}
            type="text"
            required={true}
          />
          <p className="p-0 text-xsmall text-error-50">
            {errors.contactFirstName?.message}
          </p>
        </div>
        <div>
          <Input
            {...register('contactLastName')}
            label={ct('Registration.contactLastName', 'Nom du contact')}
            placeholder={ct(
              'Registration.contactLastNamePlaceholder',
              'Entrez votre nom',
            )}
            type="text"
            required={true}
          />
          <p className="p-0 text-xsmall text-error-50">
            {errors.contactLastName?.message}
          </p>
        </div>
        <div>
          <Input
            {...register('contactPhone')}
            label={ct('Registration.contactPhone', 'Numéro de téléphone')}
            placeholder={ct(
              'Registration.contactPhonePlaceholder',
              'Entrez votre numéro de téléphone',
            )}
            type="text"
            required={true}
          />
          <p className="p-0 text-xsmall text-error-50">
            {errors.contactPhone?.message}
          </p>
        </div>
        <h2 className="text-grey2-60 font-semibold leading-6">
          {ct('Registration.address', 'Adresse de la société')}
        </h2>
        <div>
          <Input
            {...register('address_1')}
            label={ct('Registration.address_1', 'Adresse')}
            placeholder={ct(
              'Registration.addressPlaceholder',
              'Entrez une adresse',
            )}
            required={true}
            type="text"
          />
          <p className="p-0 text-xsmall text-error-50">
            {errors.address_1?.message}
          </p>
        </div>
        <div>
          <Input
            {...register('address_2')}
            label={ct('Registration.address_2', "Complément d'adresse")}
            placeholder={ct(
              'Registration.address2Placeholder',
              "Entrez un complément d'adresse",
            )}
            required={false}
            type="text"
          />
          <p className="p-0 text-xsmall text-error-50">
            {errors.address_2?.message}
          </p>
        </div>
        <div>
          <Input
            {...register('postalCode')}
            label={ct('Registration.postalCode', 'Code postal')}
            placeholder={ct(
              'Registration.postalCodePlaceholder',
              'Entrez un code postal',
            )}
            type="text"
            required={true}
          />
          <p className="p-0 text-xsmall text-error-50">
            {errors.postalCode?.message}
          </p>
        </div>
        <div>
          <Input
            {...register('city')}
            label={ct('Registration.city', 'Ville')}
            placeholder={ct('Registration.cityPlaceholder', 'Entrez une ville')}
            type="text"
            required={true}
          />
          <p className="p-0 text-xsmall text-error-50">
            {errors.city?.message}
          </p>
        </div>
        <div>
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <CatalogSelect
                options={countryOptions}
                selected={field.value}
                onChange={field.onChange}
                label={ct('Registration.country', 'Sélectionnez un pays')}
                required={true}
              />
            )}
          />
        </div>
        <p className="p-0 text-xsmall text-error-50">
          {errors.country?.message}
        </p>
        <h2 className="text-grey2-60 font-semibold leading-6">
          {ct('Registration.companyInfo', 'Informations sur la société')}
        </h2>
        <div>
          <Input
            {...register('registrationNumber')}
            label={ct('Registration.registrationNumber', 'Numéro Siret')}
            placeholder={ct(
              'Registration.numberPlaceholder',
              'Entrez un numéro',
            )}
            type="text"
            required={false}
          />
          <p className="p-0 text-xsmall text-error-50">
            {errors.registrationNumber?.message}
          </p>
        </div>
        <div>
          <Input
            {...register('vatNumber')}
            label={ct('Registration.vatNumber', 'Numéro de TVA')}
            placeholder={ct(
              'Registration.numberPlaceholder',
              'Entrez un numéro',
            )}
            type="text"
            required={false}
          />
          <p className="p-0 text-xsmall text-error-50">
            {errors.vatNumber?.message}
          </p>
        </div>
        <div>
          <label className="font-sans text-sm font-normal text-grey2-60  cursor-pointer">
            <input
              className="mr-2 custom-checkbox"
              type="checkbox"
              {...register('termsAccepted')}
            />
            <Trans
              t={t}
              i18nKey="Registration.termsLabel"
              values={{ sellerName }}
              components={{
                terms: (
                  <a
                    className="underline text-seller"
                    href={termsAndConditions}
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ),
              }}
            />
          </label>
          {errors.termsAccepted && (
            <p className="p-0 text-xsmall text-error-50">
              {errors.termsAccepted.message}
            </p>
          )}
        </div>
        <CatalogButton color="seller" width="full" size="large" type="submit">
          {formCta}
        </CatalogButton>
      </form>
    </div>
  );

  const successNotificationView = (
    <div className="flex p-6 rounded-lg bg-success-5 gap-4">
      <div className="min-w-8 h-8 border rounded-full border-black flex items-center justify-center">
        <Check className="w-6 h-6 text-black" />
      </div>
      <div className="flex flex-col gap-3">
        <div>
          <div className="text-sm font-semibold text-black">
            {ct(
              'Login.registrationSuccessTitle',
              'Votre compte client a bien été créé',
            )}
          </div>
          <p className="text-sm text-black">
            {' '}
            {ct(
              'Login.registrationSuccessText1',
              'Un mail de confirmation de votre inscription a été envoyé à {{email}}. Veuillez suivre les instructions indiquées dans le mail pour finaliser votre inscription.',
              {
                email: inputEmail,
              },
            )}
          </p>
          <p className="text-sm text-black">
            {ct(
              'Login.registrationSuccessText2',
              "Si vous ne l'avez pas reçu, veuillez vérifier votre dossier courriers indésirables",
            )}
          </p>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (hasRegistered) {
      setView(successNotificationView);
    } else {
      setView(formView);
    }
  }, [hasRegistered, loginPageContent, inputEmail]);

  return view;
};

export default RegistrationForm;
