import { Listbox, Transition } from '@headlessui/react';
import { NavArrowDown } from 'iconoir-react';
import React, { Fragment } from 'react';
import clsx from 'clsx';
import { useCatalogTranslation } from '@lib/hooks/use-catalog-translations';
import { DropdownselectOption } from '../../../../types/global';

type CatalogSelectProps = {
  options: DropdownselectOption[];
  selected: DropdownselectOption | null;
  onChange: (value: DropdownselectOption) => void;
  label?: string;
  disabled?: boolean;
  required?: boolean;
};

const CatalogSelect = ({
  options,
  selected,
  onChange,
  label,
  disabled,
  required,
}: CatalogSelectProps) => {
  const { ct } = useCatalogTranslation('common');
  const optional = ct('Common.optional', 'Optionnel');
  const handleSelect = (option: DropdownselectOption) => {
    onChange(option);
  };

  return (
    <Listbox
      defaultValue={selected}
      onChange={handleSelect}
      disabled={disabled}
    >
      {({ open }) => (
        <>
          {label && (
            <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
              {label}
              {required && <span className="text-rose-500">*</span>}
              {!required && (
                <span className="text-grey2-40 text-sm">({optional})</span>
              )}
            </Listbox.Label>
          )}
          <div className={clsx('relative', { 'opacity-50': disabled })}>
            <Listbox.Button className="mt-0 block w-full appearance-none rounded-xl border border-gray-200 bg-transparent px-4 py-[10px] shadow-sm focus:border-seller focus:border-2 focus:outline-none focus:ring-1 text-left text-sm">
              <span className="block truncate text-[16px]">
                {selected ? (
                  selected.value
                ) : (
                  <span className="text-zinc-400">{label}</span>
                )}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <NavArrowDown
                  className="h-5 w-5 text-grey2-60"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="sm:text-sm absolute z-10 mt-1 max-h-60 w-full divide-y divide-grey2-10 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {options?.map((option) => (
                  <div key={option.value} className="flex items-center gap-2">
                    <Listbox.Option
                      key={option.id}
                      className="flex h-10 w-full cursor-pointer items-center px-3 hover:bg-grey2-10"
                      value={option}
                    >
                      <label className="w-full" htmlFor={`option-${option.id}`}>
                        {option.value}
                      </label>
                    </Listbox.Option>
                  </div>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default CatalogSelect;
